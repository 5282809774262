<template>
  <div class="czzj_6357">
    <div class="tab" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logoBlack.png" alt="" />
    </div>
    <div class="step_1">
      <div class="left">
        <!-- <div @click="getActivity" class="btn"></div> -->
        <div class="signup">
          <input v-model="form.phone" type="text" placeholder="请输入手机号">
          <div class="codeBox">
            <input v-model="form.code" type="text" placeholder="请输入验证码">
            <span v-if="verControl" @click="verCode(form.phone)">获取验证码</span>
            <span v-else>重新获取({{verSecond}})</span>
          </div>
          <button @click="submit(form)"></button>
        </div>
        <img class="newBanner" src="/activity/czzj_6357/index/newBanner.png" alt="">
        <img class="red" src="/activity/czzj_6357/index/red.png" alt="">
      </div>
      <div v-if="controlImgCodeDialog" class="codeDialog">
        <img :src="imgcodelocal" alt="">
        <p>
          <span>图形验证码:</span>
          <input type="text" v-model="form.codeNumber">
        </p>
        <div>
          <button @click="()=>{this.controlImgCodeDialog = false}">取消</button>
          <button @click="confirm(form.codeNumber)">确定</button>
        </div>
      </div>
      <img class="right" src="/activity/czzj_6357/index/s3.png" alt="" />
    </div>
    <div class="w">
      <div class="step_2">
        <div class="rulesBox">
          <div class="rules" @click="rulesModel = true">活动规则</div>
        </div>
        <div class="box">
          <img
            @click="getActivity"
            src="/activity/czzj_6357/index/s5.png"
            alt=""
          />
          <img
            @click="getActivity"
            src="/activity/czzj_6357/index/s6.png"
            alt=""
          />
          <img
            @click="getActivity"
            src="/activity/czzj_6357/index/s7.png"
            alt=""
          />
        </div>
        <div class="text">首入$100即送，最高兑换$5/手</div>
        <div class="btnBox">
          <img
            @click="getActivity"
            src="/activity/czzj_6357/index/s8.png"
            alt=""
          />
        </div>
      </div>
      <div class="step_3">
        <div class="btnBox">
          <img
            @click="getLive800"
            src="/activity/czzj_6357/index/s10.png"
            alt=""
          />
          <img
            @click="getActivity"
            src="/activity/czzj_6357/index/s11.png"
            alt=""
          />
        </div>
      </div>
      <div class="step_4">
        <div class="box">
          <img class="left" src="/activity/czzj_6357/index/s13.png" alt="" />
          <div class="right">
            <img src="/activity/czzj_6357/index/s14.png" alt="" />
            <img
              @click="getActivity"
              class="btn"
              src="/activity/czzj_6357/index/s15.png"
              alt=""
            />
          </div>
        </div>
        <div class="text">下一个盈利的就是你！</div>
      </div>
      <div class="step_5">
        <img class="box" src="/activity/czzj_6357/index/s16.png" alt="" />
        <div class="btnBox">
          <div class="item">
            <img
              @click="getLive800"
              src="/activity/czzj_6357/index/s17.png"
              alt=""
            />
            <p>咨询客服，获取最新版官方APP</p>
          </div>
          <div class="item">
            <img
              @click="getActivity"
              src="/activity/czzj_6357/index/s18.png"
              alt=""
            />
            <p>盈利的快乐 这个春天你要知道</p>
          </div>
        </div>
      </div>
    </div>
    <div class="floatBox" v-if="floatBoxModel">
      <div class="box">
        <div class="qrCode">
          <img src="../../../../static/appDownload/code.png" alt="" />
        </div>
        <div class="btnBox">
          <p>开户就领</p>
          <p>
            <span style="color:#ffa200">$500</span>
            <span>体验金</span>
          </p>
        </div>
      </div>
      <div class="closeBox">
        <img
          @click="floatBoxModel = false"
          src="/activity/czzj_6357/index/closer.png"
          alt=""
        />
      </div>
    </div>
    <div class="mask" v-if="rulesModel">
      <div class="popup">
        <img
          class="guanbi1"
          @click="rulesModel = false"
          src="/activity/czzj_6357/index/s19.png"
          alt=""
        />
        <ul class="dialogContent">
          <li>
            <span>活动对象：</span>
            活动期间，在本平台开立真实账户，并首次入金的客户
          </li>
          <li>
            <span>活动时间：</span>
            即日起至2023年03月31日23:59
          </li>
          <li>
            <span>活动规则：</span><br />
            首次充值，充多少送多少美元赠金，奖励上不封顶；<br />
            72小时内总入金≥500美元，可额外获得100美元现金；<br />
            首入金奖励如下：
            <table>
              <tr>
                <th>层级</th>
                <th>首入条件<br/>(72h内)</th>
                <th>美元赠金<br/>(100%赠送)</th>
                <th>美元现金<br/>(限时送)</th>
                <th>美元现金提现条件<br/>(30天内)</th>
                <th>总获赠金额</th>
              </tr>
              <tr>
                <th>1</th>
                <th>总入金＜$500</th>
                <th>＜$500</th>
                <th>无</th>
                <th>无</th>
                <th>＜500美元赠金</th>
              </tr>
              <tr>
                <th>2</th>
                <th>总入金≥$500</th>
                <th>≥$500</th>
                <th>100</th>
                <th>≥10手</th>
                <th>≥500美元赠金<br/>+100美元现金</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th>充多少 送多少</th>
                <th>100</th>
                <th></th>
                <th></th>
              </tr>
            </table>
          </li>
          <li>
            <span>美元赠金：</span><br />
            1、活动期间内，首次入金可获得等额的美元赠金（即赠金点）回馈；<br />
            2、 首入金有效期为第一笔入金后的72h内，赠送金额将按照72h后最终数额计算；<br />
            3、用户每交易一手伦敦金/伦敦银，返还相对应的赠金金额；该笔资金可交易可提现，用户可登录App-我的-赠金奖励中查看（未满一手按照实际交易手数占一手的比例计算）<br />
            4、美元赠金按交易手数返还，当月交易越多，每手返还越高！<br />
            <span style="color:#00c1eb;font-weight: 500;">更多活动详情，请下载App-我的-赠金奖励中查看，或咨询客服。</span>
          </li>
          <li>
            <span>美元现金：</span><br />
            1、美元现金直接入账，可交易，可提现；<br />
            2、用户在30天内完成≥10手交易即可提现，品种限伦敦金/伦敦银；<br />
            3、30天内未完成手数要求，活动到期时，系统仅扣回美元现金金额。<br />
            举例说明：A女士，入金520美元，获得100美元现金，账户实际到账金额为520+100=620美元，第15天，A女士完成10手交易，顺利提取100美元现金。<br />
          </li>
          <li>
            <span>注意事项：</span><br />
            1. 客户参与活动需在本公司开立真实交易账户,参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br />
            2. 交易手数计算：开仓一手平仓一手，如此来回算一手；<br />
            3. 本活动本公司新客户仅有一次机会参与，不可重复领取；<br />
            4. 凡推广商及其名下客户不参与本活动；<br />
            5.
            本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { verifyImgCode, quickRegister } from '../../../../api/info';
import { getGuid } from '../../../../utils/guid';
export default {
  name: "czzj_6357_new",
  data() {
    return {
      form:{
        phone:'',
        code:'',
        codeNumber:'',
      },
      rulesModel: false,
      floatBoxModel: true,
      navBarFixed: false,
      // 控制显示验证码或读秒
      verControl:true,
      // 定时器倒计时
      refreshData:null,
      // 验证码读秒数
      verSecond: 60,
      // 控制图片验证码弹窗
      controlImgCodeDialog:false,
      // 图片验证码地址
      imgcodelocal:'',
      guid:'',
    };
  },
  created() {
    this.$emit("controlShow", true);
    if (document.body.clientWidth < 500) {
      this.$router.push({
        name: "czzj_6357_wap",
      });
    }
  },
  methods: {
    goback() {
      window.location.href = window.location.origin;
    },
    getActivity() {
      window.open("https://www.rlcvipltd.net/uc/signUp/real");
    },
    getLive800() {
      // window.open(
      //   "https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377"
      // );
      window.open(this.$parent.live800UrlUc)
    },
    getDownloadApp() {
      window.open("https://oss.0790jiaxiao.com/HS/software/hs_9999.apk");
    },
    // 点击获取验证码
    verCode(attr){
      if(this.isPhone(attr)){
        this.controlImgCodeDialog = true;
        this.countdowm();
        this.guid = getGuid();
        // this.imgcodelocal = window.location.origin + '/api/proxy/v4/U104?key=' + this.guid;
        this.imgcodelocal = 'https://sem.rlcmarketltd.net' + '/api/proxy/v4/U104?key=' + this.guid;
      }else{
        this.$message({
          message: '请输入正确手机号!',
          type: 'warning',
        });
      }
    },
    // 提交验证码
    confirm(attr){
      if(attr){
        let data = {
          Mobile: this.form.phone,
          Token: this.guid,
          ImgCode: Number(attr),
        }
        // 获取图片验证码接口
        verifyImgCode(data).then(res=>{
          if(res.ok == 1){
            this.$message({
              message: res.msg,
              type: 'warning'
            });
            // this.form.code = res.data;
            this.verControl = !this.verControl;
            this.controlImgCodeDialog = false;
          }else{
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            this.controlImgCodeDialog = false;
          }
        })
      }else{
        this.$message({
          message: '请输入图片验证码结果!',
          type: 'warning'
        });
      }
    },
    // 提交手机号验证码
    submit(form){
      if(form.phone && form.code){
        let data = {
          IsQuickRregister:true,
          Mobile:form.phone,
          Code:form.code,
          Token:this.guid,
          LoginType:1,
          AuthMode:1,
        };
        quickRegister(data).then(res=>{
          console.log(res);
          if(res.ok == 1){
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            window.location.href = 'https://www.rlcvipltd.net/uc/login';
          }else{
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
        })
      }else{
        this.$message({
          message: '请输入手机号和验证码！',
          type: 'warning',
        });
      }
    },
    // 读秒
    countdowm() {
      this.refreshData = window.setInterval(() => {
        this.verSecond--;
        if (this.verSecond < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.verSecond = 60;
          this.verControl = true;
        }
      }, 1000);
    },
    // 正则判断电话号码格式是否正确
    isPhone(TEL) {
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (strTemp.test(TEL)) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10px;
}
.navBarFixed {
  position: fixed;
  top: 0;
  z-index: 999;
}
.czzj_6357 {
  width: 100%;
  height: 5000px;
  background: url("/activity/czzj_6357/index/s1.png") no-repeat center;
  background-size: cover;
}

.w {
  width: 1200px;
  margin: 0 auto;
}
.step_1 {
  width: 1350px;
  height: 760px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 90px;
  .left {
    float: left;
    width: 765px;
    height: auto;
    margin-top: 35px;
    box-sizing: border-box;
    padding-top: 105px;
    padding-left: 110px;
    position: relative;
    .newBanner{
      position: absolute;
      top: -120px;
      left: 55px;
    }
    .red{
      position: absolute;
      top: 90px;
      left: -60px;
    }
    .signup{
      width: 605px;
      height: 320px;
      border-radius: 25px;
      background-color: rgba(253, 253, 253, 0.9);
      box-sizing: border-box;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      input:focus{
        outline: none;
      }
      input{
        width: 80%;
        height: 57px;
        background: #fff;
        border: 2px solid #1ba67d;
        border-radius: 18px;
        font-size: 16px;
        margin-bottom: 20px;
        text-indent: 15px;
        list-style: none;
      }
      .codeBox{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
          width: 180px;
          height: 61px;
          line-height: 60px;
          font-size: 20px;
          border-radius: 15px;
          background-color: #1ba67d;
          text-align: center;
          color: #fff;
          position: absolute;
          top: 1px;
          right: 58px;
          cursor: pointer;
          background: url('/activity/czzj_6357/index/code.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      button{
        width: 412px;
        height: 131px;
        cursor: pointer;
        background: url('/activity/czzj_6357/index/s8.png') no-repeat;
        background-size: 100% 100%;
        border: none;
        margin-top: -20px;
      }
    }
  }
  .codeDialog{
    width: 400px;
    height: 200px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fef6e9;
    border-radius: 15px;
    z-index: 99;
    border: 1px solid #ffd8a6;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    img{
      width: 120px;
      margin-bottom: 0;
    }
    p{
      span{
        color: #1ba67d;
        font-size: 18px;
        font-weight: 600;
      }
      input{
        width: 150px;
        height: 30px;
        background-color: #ffffff;
        border: none;
        margin-left: 10px;
        text-indent: 10px;
        color: #1ba67d;
      }
    }
    div{
      display: flex;
      justify-content: center;
      align-items: center;
      button{
        width: 100px;
        height: 40px;
        border: 1px solid #ff8038;
        background-color: #ffffff;
        color: #ff8038;
        font-size: 18px;
        border-radius: 8px;
        margin: 0 10px;
      }
      button:nth-child(2){
        background-color: #ff8038;
        color: #ffffff;
      }
    }
  }
  .right {
    float: left;
    width: 470px;
    margin-left: 20px;
  }
}
.step_2 {
  width: 100%;
  height: 700px;
  background: url("/activity/czzj_6357/index/s4.png") no-repeat center;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 100px;
  .rulesBox {
    text-align: center;
    .rules {
      color: #0d7260;
      font-size: 26px;
      cursor: pointer;
    }
  }
  .box {
    width: 850px;
    height: 380px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin: 0 auto;
    margin-top: -20px;
    img {
      height: 290px;
      cursor: pointer;
      &:nth-child(2) {
        height: 100%;
        position: relative;
        top: 28px;
      }
    }
  }
  .text {
    color: #0d7260;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    margin-top: 30px;
  }
  .btnBox {
    text-align: center;
    height: 107px;
    margin-top: 10px;
    img {
      height: 100%;
      cursor: pointer;
    }
  }
}
.step_3 {
  width: 100%;
  height: 1415px;
  background: url("/activity/czzj_6357/index/s9.png?v=0227") no-repeat center;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 1245px;
  margin-top: 75px;
  .btnBox {
    width: 670px;
    height: 107px;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    margin-top: 20px;
    img {
      height: 100%;
      cursor: pointer;
    }
  }
}
.step_4 {
  width: 100%;
  height: 870px;
  background: url("/activity/czzj_6357/index/s12.png") no-repeat center;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 110px;
  margin-top: 75px;
  .box {
    width: 780px;
    height: 610px;
    margin-left: 165px;
    .left {
      float: left;
      width: 303px;
    }
    .right {
      float: left;
      width: 325px;
      padding-top: 25px;
      margin-left: 145px;
      text-align: center;
      img {
        width: 100%;
      }
      .btn {
        height: 107px;
        cursor: pointer;
        margin-top: 30px;
      }
    }
  }
  .text {
    color: #0d7260;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    margin-top: 30px;
  }
}
.step_5 {
  width: 100%;
  margin-top: 75px;
  .box {
    width: 100%;
  }
  .btnBox {
    width: 1030px;
    height: 107px;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    margin-top: 70px;
    .item {
      width: 455px;
      text-align: center;
      img {
        width: 315px;
        cursor: pointer;
      }
      p {
        color: #fff;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        margin-top: 16px;
      }
    }
  }
}
.floatBox {
  position: fixed;
  top: 250px;
  right: 0px;
  .box {
    width: 230px;
    height: 364px;
    background: url("/activity/czzj_6357/index/floatBackground.png") no-repeat center;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 94px;
    .qrCode {
      text-align: center;
      img {
        width: 150px;
      }
    }
    .btnBox {
      width: 160px;
      margin: 35px auto 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p{
        margin: 0 auto;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
      }
    }
  }
  .closeBox {
    width: 230px;
    text-align: center;
    margin-top: 30px;
    img {
      height: 50px;
      cursor: pointer;
    }
  }
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
}
.popup {
  width: 1100px;
  height: 800px;
  border-radius: 20px;
  background: #e9f7f7;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%;-46%);
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0px 0px 95px 0px rgba(43, 53, 105, 0.1);
  overflow-y: scroll;
  z-index: 99999999999999;
  .guanbi1 {
    float: right;
    cursor: pointer;
    height: 40px;
    margin-top: 8px;
    margin-right: 10px;
  }
  .dialogContent {
    box-sizing: border-box;
    padding: 40px 50px 20px;
    li {
      font-size: 18px;
      color: #599b8f;
      line-height: 36px;
      span {
        font-weight: 600;
        color: #0d7260;
      }
      img {
        width: 100%;
        height: auto;
        margin: 20px 0;
      }
      table {
        border: 1px solid #0d7260;
        border-radius: 15px;
        tr {
          th {
            text-align: center;
            font-weight: 500;
            line-height: 36px;
            border-bottom: 1px solid #599b8f;
            border-right: 1px solid #599b8f;
            padding: 5px;
          }
          th:last-child {
            border-right: 1px solid transparent;
          }
          &:last-child {
            th {
              border-bottom: none;
            }
          }
        }
        tr:nth-child(1) {
          th {
            font-weight: 600;
          }
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: none;
}
</style>